import React from 'react';
import './EventPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

import affiche from '../../img/cyberbot/cyberbotv4.png'


const EventPageV3 = () => {
  return (
    // <div style={{backgroundColor:'#171F2E'}}>
    <div className="EventPageRoot">

      <NavBar />
      <div className="EventPage">
        <div className="event-container">
          <h1 className="title">ISIMM CYBERBOT V3.0</h1>
          <img src={affiche} className="video-wrapper" />
          <div className="event-details">
            {/* <div className="details-line">
            <p className="detail"><FontAwesomeIcon icon={faCalendarAlt} /> Date: 13-03-2022</p> <br/><br/>
            <p className="detail"><FontAwesomeIcon icon={faMapMarkerAlt} /> Location: Higher Institute of Informatics and Mathematics of Monastir</p>
          </div> */}
            <p className="description">
              <span><b>Description:</b></span>
              <br /><br />
              ISIMM-CYBERBOT v3.0 stands as an international event designed to facilitate the gathering of passionate and competitive individuals, fostering the improvement of their abilities, expanding their knowledge, and optimizing their robots' performance.            </p>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="footer__content">
          <Footer />
        </div>
      </footer>
    {/* <Footer/> */}

    </div>
  );
};

export default EventPageV3;